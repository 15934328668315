<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img loading="lazy" class="wp-image-23287 aligncenter" src="@/assets/news/11/1.jpg" alt="图片" width="573" height="382"  sizes="(max-width: 573px) 100vw, 573px"></p>
        <p>在后疫情时代，越来越多的国际投资者将目光投向希腊，盛行多年的“黄金签证”计划，再度受到全球瞩目。</p>
        <p><strong>获批人数再创新高</strong></p>
        <p>根据希腊移民局发布的黄金签证数据：</p>
        <p>截至2021年9月，有554名主申请人连同其1,746名家属获得了希腊黄金签证。2021年前5个月，希腊仅批准了117名主申请人，平均每月23.4名。但是，随后4个月，这一数据增长到了109.25名，月度批准量增加了367%。</p>
        <p>至此，希腊累计向9129组家庭发放了黄金居留签证，其中中国家庭占6274组，占比69%。</p>
        <p><img loading="lazy" class="alignnone size-full wp-image-23288 aligncenter" src="@/assets/news/11/2.jpg" alt="图片" width="650" height="77">
          <img loading="lazy" class="alignnone size-full wp-image-23289 aligncenter" src="@/assets/news/11/3.png" alt="图片" width="565" height="499"></p>
        <p>累计发放27449张黄金签证，其中中国投资者高达19119位，占全年总数70%。</p>
        <p><img loading="lazy" class="alignnone size-full wp-image-23290 aligncenter" src="@/assets/news/11/4.jpg" alt="图片" width="691" height="64">
          <img loading="lazy" class="alignnone size-full wp-image-23291 aligncenter" src="@/assets/news/11/5.png" alt="图片" width="565" height="499"></p>
        <p>不仅黄金签证获批数量大幅增长，其中中国申请人获希腊黄金签证的占比也始终保持在排行榜TOP-1，国家也更加多元化。</p>
        <p><strong><img loading="lazy" class="alignnone size-full wp-image-23292 aligncenter" src="@/assets/news/11/6.png" alt="图片" width="640" height="330"></strong></p>
        <p style="text-align: center;">希腊黄金签证来源国比例</p>
        <p><strong>希腊为何备受关注</strong></p>
        <p>从希腊近两年表现来看，无论是政策，还是经济数据，希腊都没有辜负投资者的青睐：希腊经济已经开始复苏，投资频现积极信号！</p>
        <p><strong>希腊房价，“涨”势不错</strong></p>
        <p>根据全球第四大评级机构DBRS的数据，希腊房价在2018-2019年期间累计上涨了9.2%，2020年尽管受旅行限制和其他针对新冠病毒的措施影响，涨幅降为4.6%，虽有所放缓，但市场表现呈积极态势。</p>
        <p>分析认为，希腊房地产市场高度依赖外国投资，导致雅典和热门旅游目的地价格大幅上涨。</p>
        <p>参考阅读：关于希腊房产，全球权威机构这样说……</p>
        <p><img loading="lazy" class="wp-image-23293 aligncenter" src="@/assets/news/11/7.jpg" alt="图片" width="615" height="410"  sizes="(max-width: 615px) 100vw, 615px"></p>
        <p><strong>希腊政府，大力支持</strong></p>
        <p>当地时间9月14日，希腊财政部副部长阿波斯托洛斯·维西洛普洛斯（Apostolos Vesyropoulos）宣布：从10月1日起，每位纳税人将能够向一级直系亲属（父母、子女以及兄弟姐妹）转让价值高达80万欧元的资产和不动产，而无需缴纳任何税费。</p>
        <p>参考阅读：免税！高达80万欧元！</p>
        <p><img loading="lazy" class="wp-image-23294 aligncenter" src="@/assets/news/11/8.jpg" alt="图片" width="733" height="539" sizes="(max-width: 733px) 100vw, 733px"></p>
        <p>在希腊政府的大力支持下，希腊房产热度难减，而海外投资者对希腊房地产的投资热情持续走高。而且疫情之下的希腊移民依然是在正常办理和审批的。随着疫情的好转，当打开国门互通有无之时，希腊黄金签证或将迎来新一波高峰。</p>
        <p>有远见的投资人何不先人一步，抢滩希腊房市。如果您也有海外身份、资产配置、子女教育等需求，可以提前行动起来了！</p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "11",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:11,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>